// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Board from "./Board.bs.js";
import * as React from "react";
import LogoSvg from "./logo.svg";

import './App.css';
;

var logo = LogoSvg;

function App(Props) {
  return React.createElement("div", {
              className: "App"
            }, React.createElement("h1", undefined, "Chess"), React.createElement(Board.make, {}));
}

var make = App;

export {
  logo ,
  make ,
}
/*  Not a pure module */
