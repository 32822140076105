// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Game from "../Game.bs.js";
import * as Init from "../modules/Init.bs.js";
import * as Piece from "./Piece.bs.js";
import * as Utils from "../modules/Utils.bs.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Boundaries from "../modules/Boundaries.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function getMoveOptions(id, team, cells) {
  if (team) {
    var previews = [];
    if (Utils.isCellOccupied(id + Game.width | 0, cells) === false) {
      previews.push(id + Game.width | 0);
    }
    if (previews.length !== 0 && Init.Cells.blackPawns.includes(id) && Utils.isCellOccupied(id + (Game.width << 1) | 0, cells) === false) {
      previews.push(id + (Game.width << 1) | 0);
    }
    var leftDiaganolTeam = Belt_Option.flatMap(Belt_Array.get(cells, (id + Game.width | 0) - 1 | 0), (function (c) {
            return Utils.getTeamFromPieceType(c.pieceType);
          }));
    var rightDiaganolTeam = Belt_Option.flatMap(Belt_Array.get(cells, (id + Game.width | 0) + 1 | 0), (function (c) {
            return Utils.getTeamFromPieceType(c.pieceType);
          }));
    if (leftDiaganolTeam !== undefined && leftDiaganolTeam !== /* Black */1 && Boundaries.isLeftBoundary(id) === false) {
      previews.push((id + Game.width | 0) - 1 | 0);
    }
    if (rightDiaganolTeam !== undefined && rightDiaganolTeam !== /* Black */1 && Boundaries.isRightBoundary(id) === false) {
      previews.push((id + Game.width | 0) + 1 | 0);
    }
    return previews;
  }
  var previews$1 = [];
  if (Utils.isCellOccupied(id - Game.width | 0, cells) === false) {
    previews$1.push(id - Game.width | 0);
  }
  if (previews$1.length !== 0 && Init.Cells.whitePawns.includes(id) && Utils.isCellOccupied(id - (Game.width << 1) | 0, cells) === false) {
    previews$1.push(id - (Game.width << 1) | 0);
  }
  var leftDiaganolTeam$1 = Belt_Option.flatMap(Belt_Array.get(cells, (id - Game.width | 0) - 1 | 0), (function (c) {
          return Utils.getTeamFromPieceType(c.pieceType);
        }));
  var rightDiaganolTeam$1 = Belt_Option.flatMap(Belt_Array.get(cells, (id - Game.width | 0) + 1 | 0), (function (c) {
          return Utils.getTeamFromPieceType(c.pieceType);
        }));
  if (leftDiaganolTeam$1 !== undefined && leftDiaganolTeam$1 !== /* White */0 && Boundaries.isLeftBoundary(id) === false) {
    previews$1.push((id - Game.width | 0) - 1 | 0);
  }
  if (rightDiaganolTeam$1 !== undefined && rightDiaganolTeam$1 !== /* White */0 && Boundaries.isRightBoundary(id) === false) {
    previews$1.push((id - Game.width | 0) + 1 | 0);
  }
  return previews$1;
}

function Pawn(Props) {
  var team = Props.team;
  var turn = Props.turn;
  return React.createElement(Piece.make, {
              image: team ? "/images/black_pawn.png" : "/images/white_pawn.png",
              pieceName: "Pawn",
              turn: turn
            });
}

var make = Pawn;

export {
  getMoveOptions ,
  make ,
}
/* Game Not a pure module */
