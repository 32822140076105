// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Game from "../Game.bs.js";
import * as Piece from "./Piece.bs.js";
import * as Utils from "../modules/Utils.bs.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Boundaries from "../modules/Boundaries.bs.js";

function isCellThreateneed(id, team, cells) {
  var cell = Belt_Array.get(cells, id);
  if (cell !== undefined) {
    var match = cell.threatState;
    if (team === /* White */0) {
      return match[1];
    } else {
      return match[0];
    }
  }
  console.log("ignoring isCellThreateneed cell because its ID is out of bounds");
  return false;
}

function getMoveOptions(id, team, cells) {
  var moveOptions = [];
  var topRight = (id - Game.width | 0) - 1 | 0;
  if (topRight > 0 && Boundaries.isInBounds(topRight) && Utils.isOwnTeamCell(topRight, team, cells) === false && isCellThreateneed(topRight, team, cells) === false) {
    moveOptions.push((id - Game.width | 0) - 1 | 0);
  }
  var top = id - Game.width | 0;
  if (top > 0 && Boundaries.isInBounds(top) && Utils.isOwnTeamCell(top, team, cells) === false && isCellThreateneed(top, team, cells) === false) {
    moveOptions.push(id - Game.width | 0);
  }
  var topLeft = (id - Game.width | 0) + 1 | 0;
  if (topLeft > 0 && Boundaries.isInBounds(topLeft) && Utils.isOwnTeamCell(topLeft, team, cells) === false && isCellThreateneed(topLeft, team, cells) === false) {
    moveOptions.push((id - Game.width | 0) + 1 | 0);
  }
  var left = id - 1 | 0;
  if (Boundaries.isInBounds(left) && Boundaries.isRightBoundary(left) === false && Utils.isOwnTeamCell(left, team, cells) === false && isCellThreateneed(left, team, cells) === false) {
    moveOptions.push(id - 1 | 0);
  }
  var right = id + 1 | 0;
  if (Boundaries.isInBounds(right) && Boundaries.isLeftBoundary(right) === false && Utils.isOwnTeamCell(right, team, cells) === false && isCellThreateneed(right, team, cells) === false) {
    moveOptions.push(id + 1 | 0);
  }
  var bottomRight = (id + Game.width | 0) - 1 | 0;
  if (Boundaries.isInBounds(bottomRight) && bottomRight < Game.area && Utils.isOwnTeamCell(bottomRight, team, cells) === false && isCellThreateneed(bottomRight, team, cells) === false) {
    moveOptions.push((id + Game.width | 0) - 1 | 0);
  }
  var bottomLeft = (id + Game.width | 0) + 1 | 0;
  if (Boundaries.isInBounds(bottomLeft) && bottomLeft < Game.area && Utils.isOwnTeamCell(bottomLeft, team, cells) === false && isCellThreateneed(bottomLeft, team, cells) === false) {
    moveOptions.push((id + Game.width | 0) + 1 | 0);
  }
  var bottom = id + Game.width | 0;
  if (Boundaries.isInBounds(bottom) && bottom < Game.area && Utils.isOwnTeamCell(bottom, team, cells) === false && isCellThreateneed(bottom, team, cells) === false) {
    moveOptions.push(id + Game.width | 0);
  }
  return moveOptions;
}

function King(Props) {
  var team = Props.team;
  var turn = Props.turn;
  return React.createElement(Piece.make, {
              image: team ? "/images/black_king.png" : "/images/white_king.png",
              pieceName: "Bishop",
              turn: turn
            });
}

var make = King;

export {
  isCellThreateneed ,
  getMoveOptions ,
  make ,
}
/* Game Not a pure module */
