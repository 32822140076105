// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cell from "./Cell.bs.js";
import * as Game from "./Game.bs.js";
import * as Init from "./modules/Init.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as CellClick from "./modules/CellClick.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

var state_cells = Init.Cells.make(undefined);

var state = {
  cells: state_cells,
  turn: /* White */0,
  winner: undefined
};

function reducer(state, action) {
  if (Belt_Option.isNone(state.winner)) {
    return CellClick.make(state, action._0);
  } else {
    return state;
  }
}

var boardWidth = String(Math.imul(Game.width, Game.cellSize)) + "px";

function Board(Props) {
  var match = React.useReducer(reducer, state);
  var dispatch = match[1];
  var state$1 = match[0];
  var match$1 = state$1.winner;
  return React.createElement(React.Fragment, undefined, React.createElement("h2", undefined, match$1 !== undefined ? (
                    match$1 ? "Black wins!" : "White wins!"
                  ) : ""), React.createElement("div", {
                  style: {
                    display: "flex",
                    marginBottom: "2rem",
                    justifyContent: "center"
                  }
                }, React.createElement("div", {
                      style: {
                        border: "4px solid black",
                        display: "flex",
                        maxWidth: boardWidth,
                        flexWrap: "wrap",
                        transition: "all ease-in-out 1s",
                        transform: "rotate(" + (
                          state$1.turn === /* Black */1 ? "180deg" : "0"
                        ) + ")"
                      }
                    }, Belt_Array.mapWithIndex(state$1.cells, (function (i, cell) {
                            return React.createElement(Cell.make, {
                                        cell: cell,
                                        handleClick: (function (id) {
                                            Curry._1(dispatch, /* CellClicked */{
                                                  _0: id
                                                });
                                          }),
                                        turn: state$1.turn,
                                        key: "cell" + String(i)
                                      });
                          })))));
}

var make = Board;

export {
  state ,
  reducer ,
  boardWidth ,
  make ,
}
/* state Not a pure module */
