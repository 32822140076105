// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Game from "./Game.bs.js";
import * as King from "./pieces/King.bs.js";
import * as Pawn from "./pieces/Pawn.bs.js";
import * as Rook from "./pieces/Rook.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Queen from "./pieces/Queen.bs.js";
import * as React from "react";
import * as Bishop from "./pieces/Bishop.bs.js";
import * as Knight from "./pieces/Knight.bs.js";

function getBgColor(id, cellState) {
  if (cellState === /* Selected */1) {
    return "#AEB888";
  }
  if (cellState === /* MovePreview */2) {
    return "#B89A88";
  }
  var isEvenRow = (id / 8 | 0) % 2 === 0;
  var isBlack = id % 2 === (
    isEvenRow ? 1 : 0
  );
  if (isBlack) {
    return "#777";
  } else {
    return "#dadada";
  }
}

function Cell(Props) {
  var cell = Props.cell;
  var handleClick = Props.handleClick;
  var turn = Props.turn;
  var team = cell.pieceType;
  var tmp;
  if (typeof team === "number") {
    tmp = React.createElement(React.Fragment, undefined);
  } else {
    switch (team.TAG | 0) {
      case /* PawnCell */0 :
          tmp = React.createElement(Pawn.make, {
                team: team._0,
                turn: turn
              });
          break;
      case /* KnightCell */1 :
          tmp = React.createElement(Knight.make, {
                team: team._0,
                turn: turn
              });
          break;
      case /* RookCell */2 :
          tmp = React.createElement(Rook.make, {
                team: team._0,
                turn: turn
              });
          break;
      case /* BishopCell */3 :
          tmp = React.createElement(Bishop.make, {
                team: team._0,
                turn: turn
              });
          break;
      case /* QueenCell */4 :
          tmp = React.createElement(Queen.make, {
                team: team._0,
                turn: turn
              });
          break;
      case /* KingCell */5 :
          tmp = React.createElement(King.make, {
                team: team._0,
                turn: turn
              });
          break;
      
    }
  }
  return React.createElement("div", {
              style: {
                backgroundColor: getBgColor(cell.id, cell.cellState),
                display: "grid",
                height: Game.cellSizePx,
                width: Game.cellSizePx,
                placeItems: "center"
              },
              onClick: (function (param) {
                  Curry._1(handleClick, cell.id);
                })
            }, tmp);
}

var make = Cell;

export {
  getBgColor ,
  make ,
}
/* Game Not a pure module */
