// Generated by ReScript, PLEASE EDIT WITH CARE


var area = 64;

var cellSizePx = String(45) + "px";

var width = 8;

var height = 8;

var cellSize = 45;

export {
  width ,
  height ,
  area ,
  cellSize ,
  cellSizePx ,
}
/* cellSizePx Not a pure module */
