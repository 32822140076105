// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Game from "../Game.bs.js";
import * as Piece from "./Piece.bs.js";
import * as Utils from "../modules/Utils.bs.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Boundaries from "../modules/Boundaries.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var CellNotFound = /* @__PURE__ */Caml_exceptions.create("Queen.CellNotFound");

function addOptionsTo0(_moveOptionsOpt, _id, team, cells) {
  while(true) {
    var moveOptionsOpt = _moveOptionsOpt;
    var id = _id;
    var moveOptions = moveOptionsOpt !== undefined ? moveOptionsOpt : [];
    var isOppositeTeam = Utils.checkOppositeTeam(id, cells, team);
    if (!(id > Game.width && isOppositeTeam === false)) {
      return moveOptions;
    }
    var c = Belt_Array.get(cells, id - Game.width | 0);
    var nextCell;
    if (c !== undefined) {
      nextCell = c;
    } else {
      throw {
            RE_EXN_ID: CellNotFound,
            _1: "No nextCell in addOptionsTo0",
            Error: new Error()
          };
    }
    var t = Utils.getTeamFromPieceType(nextCell.pieceType);
    if (t !== undefined && t === team) {
      return moveOptions;
    }
    _id = id - Game.width | 0;
    _moveOptionsOpt = Belt_Array.concat(moveOptions, [id - Game.width | 0]);
    continue ;
  };
}

function addOptionsToArea(_moveOptionsOpt, _id, team, cells) {
  while(true) {
    var moveOptionsOpt = _moveOptionsOpt;
    var id = _id;
    var moveOptions = moveOptionsOpt !== undefined ? moveOptionsOpt : [];
    var isOppositeTeam = Utils.checkOppositeTeam(id, cells, team);
    if (!(id < (Game.area - Game.width | 0) && isOppositeTeam === false)) {
      return moveOptions;
    }
    var c = Belt_Array.get(cells, id + Game.width | 0);
    var nextCell;
    if (c !== undefined) {
      nextCell = c;
    } else {
      throw {
            RE_EXN_ID: CellNotFound,
            _1: "No nextCell in addOptionsToArea with id: " + String(id) + "",
            Error: new Error()
          };
    }
    var t = Utils.getTeamFromPieceType(nextCell.pieceType);
    if (t !== undefined && t === team) {
      return moveOptions;
    }
    _id = id + Game.width | 0;
    _moveOptionsOpt = Belt_Array.concat(moveOptions, [id + Game.width | 0]);
    continue ;
  };
}

function addHorizontalOptions(_moveOptionsOpt, _id, team, cells, direction) {
  while(true) {
    var moveOptionsOpt = _moveOptionsOpt;
    var id = _id;
    var moveOptions = moveOptionsOpt !== undefined ? moveOptionsOpt : [];
    var isOppositeTeam = Utils.checkOppositeTeam(id, cells, team);
    var isBoundaryCell = direction === /* Left */0 ? Boundaries.isLeftBoundary(id) : Boundaries.isRightBoundary(id);
    var nextId = direction === /* Left */0 ? id - 1 | 0 : id + 1 | 0;
    if (isOppositeTeam || isBoundaryCell) {
      return moveOptions;
    }
    var nextCell = Belt_Option.getExn(Belt_Array.get(cells, nextId));
    var t = Utils.getTeamFromPieceType(nextCell.pieceType);
    if (t !== undefined && t === team) {
      return moveOptions;
    }
    _id = nextId;
    _moveOptionsOpt = Belt_Array.concat(moveOptions, [nextId]);
    continue ;
  };
}

function getOptionsByDirection(_moveOptionsOpt, _id, team, cells, direction) {
  while(true) {
    var moveOptionsOpt = _moveOptionsOpt;
    var id = _id;
    var moveOptions = moveOptionsOpt !== undefined ? moveOptionsOpt : [];
    var nextId;
    switch (direction) {
      case /* TopLeft */0 :
          nextId = (id - Game.width | 0) - 1 | 0;
          break;
      case /* TopRight */1 :
          nextId = (id - Game.width | 0) + 1 | 0;
          break;
      case /* BottomLeft */2 :
          nextId = (id + Game.width | 0) - 1 | 0;
          break;
      case /* BottomRight */3 :
          nextId = (id + Game.width | 0) + 1 | 0;
          break;
      
    }
    var isOutOfBounds = nextId > Game.area || nextId < 0;
    var isBoundaryExceptInitialId = Boundaries.isBoundary(id) && moveOptions.length !== 0;
    var initialIdStops = moveOptions.length === 0 && (
      direction !== 1 && direction < 3 ? Boundaries.isLeftBoundary(id) : Boundaries.isRightBoundary(id)
    );
    if (isOutOfBounds || isBoundaryExceptInitialId || initialIdStops) {
      return moveOptions;
    }
    var isOppositeTeam = Utils.checkOppositeTeam(id, cells, team);
    if (isOppositeTeam) {
      return moveOptions;
    }
    var c = Belt_Array.get(cells, nextId);
    var nextCell;
    if (c !== undefined) {
      nextCell = c;
    } else {
      throw {
            RE_EXN_ID: Utils.CellNotFound,
            _1: "Next cell not found in getOptionsByDirection at id " + String(nextId) + "",
            Error: new Error()
          };
    }
    var t = Utils.getTeamFromPieceType(nextCell.pieceType);
    if (t !== undefined && t === team) {
      return moveOptions;
    }
    _id = nextId;
    _moveOptionsOpt = Belt_Array.concat(moveOptions, [nextId]);
    continue ;
  };
}

function getMoveOptions(id, team, cells) {
  var upOptions = addOptionsTo0(undefined, id, team, cells);
  var downOptions = addOptionsToArea(undefined, id, team, cells);
  var horizontalOptionsLeft = addHorizontalOptions(undefined, id, team, cells, /* Left */0);
  var horizontalOptionsRight = addHorizontalOptions(undefined, id, team, cells, /* Right */1);
  return Belt_Array.concatMany([
              getOptionsByDirection(undefined, id, team, cells, /* TopLeft */0),
              getOptionsByDirection(undefined, id, team, cells, /* TopRight */1),
              getOptionsByDirection(undefined, id, team, cells, /* BottomLeft */2),
              getOptionsByDirection(undefined, id, team, cells, /* BottomRight */3),
              upOptions,
              downOptions,
              horizontalOptionsLeft,
              horizontalOptionsRight
            ]);
}

function Queen(Props) {
  var team = Props.team;
  var turn = Props.turn;
  return React.createElement(Piece.make, {
              image: team ? "/images/black_queen.png" : "/images/white_queen.png",
              pieceName: "Queen",
              turn: turn
            });
}

var make = Queen;

export {
  CellNotFound ,
  addOptionsTo0 ,
  addOptionsToArea ,
  addHorizontalOptions ,
  getOptionsByDirection ,
  getMoveOptions ,
  make ,
}
/* Game Not a pure module */
