// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function Piece(Props) {
  var image = Props.image;
  var pieceName = Props.pieceName;
  var turn = Props.turn;
  return React.createElement("div", {
              style: {
                width: "100%",
                transform: "rotate(" + (
                  turn === /* Black */1 ? "180deg" : "0"
                ) + ")"
              }
            }, React.createElement("img", {
                  style: {
                    maxWidth: "100%"
                  },
                  alt: pieceName,
                  src: image
                }));
}

var make = Piece;

export {
  make ,
}
/* react Not a pure module */
