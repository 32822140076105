// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Game from "../Game.bs.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";

function isRightBoundary(cellId) {
  return Caml_int32.mod_(cellId, Game.width) === 7;
}

function isLeftBoundary(cellId) {
  return Caml_int32.mod_(cellId, Game.width) === 0;
}

function isBoundary(cellId) {
  if (isRightBoundary(cellId)) {
    return true;
  } else {
    return isLeftBoundary(cellId);
  }
}

function crossesHorizontalBoundary(_start, end) {
  while(true) {
    var start = _start;
    if ((end - start | 0) === 0) {
      return false;
    }
    if (isLeftBoundary(start) || isRightBoundary(start)) {
      return true;
    }
    _start = start + 1 | 0;
    continue ;
  };
}

function isInBounds(id) {
  if (id >= 0) {
    return id < Game.area;
  } else {
    return false;
  }
}

export {
  isRightBoundary ,
  isLeftBoundary ,
  isBoundary ,
  crossesHorizontalBoundary ,
  isInBounds ,
}
/* Game Not a pure module */
