// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Game from "../Game.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var whitePawns = [
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55
];

var blackPawns = [
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15
];

function make(param) {
  var cells = [];
  for(var i = 0; i < Game.area; ++i){
    var whitePawn = whitePawns.find((function(i){
        return function (c) {
          return c === i;
        }
        }(i)));
    var blackPawn = blackPawns.find((function(i){
        return function (c) {
          return c === i;
        }
        }(i)));
    cells.push({
          id: i,
          pieceType: Belt_Option.isSome(whitePawn === undefined ? undefined : Caml_option.some(whitePawn)) ? ({
                TAG: /* PawnCell */0,
                _0: /* White */0
              }) : (
              Belt_Option.isSome(blackPawn === undefined ? undefined : Caml_option.some(blackPawn)) ? ({
                    TAG: /* PawnCell */0,
                    _0: /* Black */1
                  }) : (
                  i === 0 || i === 7 ? ({
                        TAG: /* RookCell */2,
                        _0: /* Black */1
                      }) : (
                      i === 56 || i === 63 ? ({
                            TAG: /* RookCell */2,
                            _0: /* White */0
                          }) : (
                          i === 57 || i === 62 ? ({
                                TAG: /* KnightCell */1,
                                _0: /* White */0
                              }) : (
                              i === 1 || i === 6 ? ({
                                    TAG: /* KnightCell */1,
                                    _0: /* Black */1
                                  }) : (
                                  i === 58 || i === 61 ? ({
                                        TAG: /* BishopCell */3,
                                        _0: /* White */0
                                      }) : (
                                      i === 2 || i === 5 ? ({
                                            TAG: /* BishopCell */3,
                                            _0: /* Black */1
                                          }) : (
                                          i === 60 ? ({
                                                TAG: /* KingCell */5,
                                                _0: /* White */0
                                              }) : (
                                              i === 4 ? ({
                                                    TAG: /* KingCell */5,
                                                    _0: /* Black */1
                                                  }) : (
                                                  i === 59 ? ({
                                                        TAG: /* QueenCell */4,
                                                        _0: /* White */0
                                                      }) : (
                                                      i === 3 ? ({
                                                            TAG: /* QueenCell */4,
                                                            _0: /* Black */1
                                                          }) : /* Blank */0
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
          cellState: /* None */0,
          threatState: [
            false,
            false
          ]
        });
  }
  return cells;
}

var Cells_blackRook = [
  0,
  7
];

var Cells_whiteRook = [
  56,
  63
];

var Cells_blackKnight = [
  1,
  6
];

var Cells_whiteKnight = [
  57,
  62
];

var Cells_blackBishop = [
  2,
  5
];

var Cells_whiteBishop = [
  58,
  61
];

var Cells = {
  blackKing: 4,
  whiteKing: 60,
  whiteQueen: 59,
  blackQueen: 3,
  blackRook: Cells_blackRook,
  whiteRook: Cells_whiteRook,
  blackKnight: Cells_blackKnight,
  whiteKnight: Cells_whiteKnight,
  blackBishop: Cells_blackBishop,
  whiteBishop: Cells_whiteBishop,
  whitePawns: whitePawns,
  blackPawns: blackPawns,
  make: make
};

export {
  Cells ,
}
/* Game Not a pure module */
