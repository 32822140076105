// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Game from "../Game.bs.js";
import * as Piece from "./Piece.bs.js";
import * as Utils from "../modules/Utils.bs.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Boundaries from "../modules/Boundaries.bs.js";

function horizontalNeighbors(id) {
  var neighbors = [];
  var idBehind = id - 1 | 0;
  var idAhead = id + 1 | 0;
  if (Boundaries.isRightBoundary(idBehind) === false) {
    neighbors.push(idBehind);
  }
  if (Boundaries.isLeftBoundary(idAhead) === false) {
    neighbors.push(idAhead);
  }
  return neighbors;
}

function verticalNeighbors(id) {
  return [
          id + Game.width | 0,
          id - Game.width | 0
        ];
}

function getMoveOptions(id, team, cells) {
  var topMoveOptions = horizontalNeighbors(id - (Game.width << 1) | 0);
  var bottomMoveOptions = horizontalNeighbors(id + (Game.width << 1) | 0);
  var rightMoveOptions = Boundaries.crossesHorizontalBoundary(id, id + 2 | 0) ? [] : verticalNeighbors(id + 2 | 0);
  var leftMoveOptions = Boundaries.crossesHorizontalBoundary(id - 2 | 0, id) ? [] : verticalNeighbors(id - 2 | 0);
  return Belt_Array.concatMany([
                topMoveOptions,
                bottomMoveOptions,
                rightMoveOptions,
                leftMoveOptions
              ]).filter(function (cellId) {
              var isInBounds = cellId >= 0 && cellId < Game.area;
              var cell = Belt_Array.get(cells, cellId);
              var isSameTeam;
              if (cell !== undefined) {
                var t = Utils.getTeamFromPieceType(cell.pieceType);
                isSameTeam = t !== undefined ? t === team : false;
              } else {
                isSameTeam = false;
              }
              if (isInBounds) {
                return isSameTeam === false;
              } else {
                return false;
              }
            });
}

function Knight(Props) {
  var team = Props.team;
  var turn = Props.turn;
  return React.createElement(Piece.make, {
              image: team ? "/images/black_knight.png" : "/images/white_knight.png",
              pieceName: "Knight",
              turn: turn
            });
}

var make = Knight;

export {
  horizontalNeighbors ,
  verticalNeighbors ,
  getMoveOptions ,
  make ,
}
/* Game Not a pure module */
