// Generated by ReScript, PLEASE EDIT WITH CARE

import * as King from "../pieces/King.bs.js";
import * as Pawn from "../pieces/Pawn.bs.js";
import * as Rook from "../pieces/Rook.bs.js";
import * as Queen from "../pieces/Queen.bs.js";
import * as Utils from "./Utils.bs.js";
import * as Bishop from "../pieces/Bishop.bs.js";
import * as Knight from "../pieces/Knight.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkmate from "./Checkmate.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as MapThreats from "./MapThreats.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var InvalidState = /* @__PURE__ */Caml_exceptions.create("CellClick.InvalidState");

function createMoveOptions(id, pieceType, cells) {
  if (typeof pieceType === "number") {
    return [];
  }
  switch (pieceType.TAG | 0) {
    case /* PawnCell */0 :
        return Pawn.getMoveOptions(id, pieceType._0, cells);
    case /* KnightCell */1 :
        return Knight.getMoveOptions(id, pieceType._0, cells);
    case /* RookCell */2 :
        return Rook.getMoveOptions(id, pieceType._0, cells);
    case /* BishopCell */3 :
        return Bishop.getMoveOptions(id, pieceType._0, cells);
    case /* QueenCell */4 :
        return Queen.getMoveOptions(id, pieceType._0, cells);
    case /* KingCell */5 :
        return King.getMoveOptions(id, pieceType._0, cells);
    
  }
}

function handleSelectedFromNone(i, cell, clickedId, previewOptions) {
  if (Caml_obj.equal(i, clickedId) && cell.pieceType !== /* Blank */0) {
    return {
            id: cell.id,
            pieceType: cell.pieceType,
            cellState: /* Selected */1,
            threatState: cell.threatState
          };
  } else if (Belt_Option.isSome(Caml_option.undefined_to_opt(previewOptions.find(function (p) {
                  return Caml_obj.equal(p, i);
                })))) {
    return {
            id: cell.id,
            pieceType: cell.pieceType,
            cellState: /* MovePreview */2,
            threatState: cell.threatState
          };
  } else {
    return {
            id: cell.id,
            pieceType: cell.pieceType,
            cellState: /* None */0,
            threatState: cell.threatState
          };
  }
}

function alternateTurn(turn) {
  if (turn === /* White */0) {
    return /* Black */1;
  } else {
    return /* White */0;
  }
}

function make(state, clickedId) {
  var cellToSelect = Belt_Option.getExn(Caml_option.undefined_to_opt(state.cells.find(function (c) {
                return clickedId === c.id;
              })));
  var match = cellToSelect.cellState;
  switch (match) {
    case /* None */0 :
        var match$1 = state.turn;
        var match$2 = Utils.getTeamFromPieceType(cellToSelect.pieceType);
        if (match$2 !== undefined && match$1 === match$2) {
          var movePreviewOptions = createMoveOptions(clickedId, cellToSelect.pieceType, state.cells);
          return {
                  cells: Belt_Array.mapWithIndex(state.cells, (function (i, c) {
                          return handleSelectedFromNone(i, c, clickedId, movePreviewOptions);
                        })),
                  turn: state.turn,
                  winner: state.winner
                };
        }
        return {
                cells: Belt_Array.map(state.cells, (function (c) {
                        return {
                                id: c.id,
                                pieceType: c.pieceType,
                                cellState: /* None */0,
                                threatState: c.threatState
                              };
                      })),
                turn: state.turn,
                winner: state.winner
              };
    case /* Selected */1 :
        return {
                cells: Belt_Array.map(state.cells, (function (c) {
                        return {
                                id: c.id,
                                pieceType: c.pieceType,
                                cellState: /* None */0,
                                threatState: c.threatState
                              };
                      })),
                turn: state.turn,
                winner: state.winner
              };
    case /* MovePreview */2 :
        var c = state.cells.find(function (c) {
              return c.cellState === /* Selected */1;
            });
        var selectedCell;
        if (c !== undefined) {
          selectedCell = c;
        } else {
          throw {
                RE_EXN_ID: InvalidState,
                _1: "Preview cell selected without a cell in the Selected State",
                Error: new Error()
              };
        }
        var newCells = Belt_Array.mapWithIndex(state.cells, (function (i, c) {
                if (c.id === clickedId) {
                  return {
                          id: i,
                          pieceType: selectedCell.pieceType,
                          cellState: /* None */0,
                          threatState: [
                            false,
                            false
                          ]
                        };
                } else if (selectedCell.id === i) {
                  return {
                          id: c.id,
                          pieceType: /* Blank */0,
                          cellState: /* None */0,
                          threatState: [
                            false,
                            false
                          ]
                        };
                } else {
                  return {
                          id: c.id,
                          pieceType: c.pieceType,
                          cellState: /* None */0,
                          threatState: [
                            false,
                            false
                          ]
                        };
                }
              }));
        var threatenedCells = MapThreats.make(newCells);
        var newCellsWithThreats = Belt_Array.map(newCells, (function (cell) {
                var newThreatState = Belt_Array.reduce(threatenedCells.filter(function (param) {
                          return param[1] === cell.id;
                        }), cell.threatState, (function (agg, cur) {
                        return Utils.updateThreatState(agg, cur[0], true);
                      }));
                return {
                        id: cell.id,
                        pieceType: cell.pieceType,
                        cellState: cell.cellState,
                        threatState: newThreatState
                      };
              }));
        var isCheckmate = Checkmate.make(newCellsWithThreats, state.turn);
        var match$3 = state.turn;
        return {
                cells: newCellsWithThreats,
                turn: alternateTurn(state.turn),
                winner: isCheckmate ? (
                    match$3 ? /* Black */1 : /* White */0
                  ) : undefined
              };
    
  }
}

export {
  InvalidState ,
  createMoveOptions ,
  handleSelectedFromNone ,
  alternateTurn ,
  make ,
}
/* King Not a pure module */
