// Generated by ReScript, PLEASE EDIT WITH CARE

import * as King from "../pieces/King.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function make(cells, team) {
  var otherTeam = team === /* White */0 ? /* Black */1 : /* White */0;
  var kingCell = Belt_Option.getExn(Caml_option.undefined_to_opt(cells.find(function (c) {
                return Caml_obj.equal(c.pieceType, {
                            TAG: /* KingCell */5,
                            _0: otherTeam
                          });
              })));
  var moveOptions = King.getMoveOptions(kingCell.id, otherTeam, cells);
  var match = kingCell.threatState;
  console.log("King");
  console.log(kingCell);
  console.log(moveOptions.length);
  console.log(moveOptions);
  if (moveOptions.length !== 0) {
    return false;
  } else if (otherTeam) {
    return match[0];
  } else {
    return match[1];
  }
}

export {
  make ,
}
/* King Not a pure module */
