// Generated by ReScript, PLEASE EDIT WITH CARE

import * as King from "../pieces/King.bs.js";
import * as Pawn from "../pieces/Pawn.bs.js";
import * as Rook from "../pieces/Rook.bs.js";
import * as Queen from "../pieces/Queen.bs.js";
import * as Utils from "./Utils.bs.js";
import * as Bishop from "../pieces/Bishop.bs.js";
import * as Knight from "../pieces/Knight.bs.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MutableSet from "rescript/lib/es6/belt_MutableSet.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var NoTeamException = /* @__PURE__ */Caml_exceptions.create("MapThreats.NoTeamException");

function cmp(param, param$1) {
  var c = Caml_obj.compare(param[0], param$1[0]);
  if (c !== 0) {
    return c;
  } else {
    return Caml_obj.compare(param[1], param$1[1]);
  }
}

var ThreatComparator = Belt_Id.MakeComparable({
      cmp: cmp
    });

function make(cells) {
  var set = Belt_MutableSet.make(ThreatComparator);
  for(var i = 0 ,i_finish = cells.length; i < i_finish; ++i){
    var cell = Belt_Option.getExn(Belt_Array.get(cells, i));
    var team = Utils.getTeamFromPieceType(cell.pieceType);
    var t = cell.pieceType;
    var newPreviews;
    if (typeof t === "number") {
      newPreviews = [];
    } else {
      switch (t.TAG | 0) {
        case /* PawnCell */0 :
            newPreviews = Pawn.getMoveOptions(cell.id, t._0, cells);
            break;
        case /* KnightCell */1 :
            newPreviews = Knight.getMoveOptions(cell.id, t._0, cells);
            break;
        case /* RookCell */2 :
            newPreviews = Rook.getMoveOptions(cell.id, t._0, cells);
            break;
        case /* BishopCell */3 :
            newPreviews = Bishop.getMoveOptions(cell.id, t._0, cells);
            break;
        case /* QueenCell */4 :
            newPreviews = Queen.getMoveOptions(cell.id, t._0, cells);
            break;
        case /* KingCell */5 :
            newPreviews = King.getMoveOptions(cell.id, t._0, cells);
            break;
        
      }
    }
    for(var j = 0 ,j_finish = newPreviews.length; j < j_finish; ++j){
      var previewId = Belt_Option.getExn(Belt_Array.get(newPreviews, j));
      if (team !== undefined) {
        Belt_MutableSet.add(set, [
              team,
              previewId
            ]);
      } else {
        throw {
              RE_EXN_ID: NoTeamException,
              Error: new Error()
            };
      }
    }
  }
  return Belt_MutableSet.toArray(set);
}

export {
  NoTeamException ,
  ThreatComparator ,
  make ,
}
/* ThreatComparator Not a pure module */
