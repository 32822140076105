// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Game from "../Game.bs.js";
import * as Piece from "./Piece.bs.js";
import * as Utils from "../modules/Utils.bs.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Boundaries from "../modules/Boundaries.bs.js";

function getOptionsByDirection(_moveOptionsOpt, _id, team, cells, direction) {
  while(true) {
    var moveOptionsOpt = _moveOptionsOpt;
    var id = _id;
    var moveOptions = moveOptionsOpt !== undefined ? moveOptionsOpt : [];
    var nextId;
    switch (direction) {
      case /* TopLeft */0 :
          nextId = (id - Game.width | 0) - 1 | 0;
          break;
      case /* TopRight */1 :
          nextId = (id - Game.width | 0) + 1 | 0;
          break;
      case /* BottomLeft */2 :
          nextId = (id + Game.width | 0) - 1 | 0;
          break;
      case /* BottomRight */3 :
          nextId = (id + Game.width | 0) + 1 | 0;
          break;
      
    }
    var isOutOfBounds = nextId > Game.area || nextId < 0;
    var isBoundaryExceptInitialId = Boundaries.isBoundary(id) && moveOptions.length !== 0;
    var initialIdStops = moveOptions.length === 0 && (
      direction !== 1 && direction < 3 ? Boundaries.isLeftBoundary(id) : Boundaries.isRightBoundary(id)
    );
    if (isOutOfBounds || isBoundaryExceptInitialId || initialIdStops) {
      return moveOptions;
    }
    var isOppositeTeam = Utils.checkOppositeTeam(id, cells, team);
    if (isOppositeTeam) {
      return moveOptions;
    }
    var c = Belt_Array.get(cells, nextId);
    var nextCell;
    if (c !== undefined) {
      nextCell = c;
    } else {
      throw {
            RE_EXN_ID: Utils.CellNotFound,
            _1: "Next cell not found in getOptionsByDirection at id " + String(nextId) + "",
            Error: new Error()
          };
    }
    var t = Utils.getTeamFromPieceType(nextCell.pieceType);
    if (t !== undefined && t === team) {
      return moveOptions;
    }
    _id = nextId;
    _moveOptionsOpt = Belt_Array.concat(moveOptions, [nextId]);
    continue ;
  };
}

function getMoveOptions(id, team, cells) {
  return Belt_Array.concatMany([
              getOptionsByDirection(undefined, id, team, cells, /* TopLeft */0),
              getOptionsByDirection(undefined, id, team, cells, /* TopRight */1),
              getOptionsByDirection(undefined, id, team, cells, /* BottomLeft */2),
              getOptionsByDirection(undefined, id, team, cells, /* BottomRight */3)
            ]);
}

function Bishop(Props) {
  var team = Props.team;
  var turn = Props.turn;
  return React.createElement(Piece.make, {
              image: team ? "/images/black_bishop.png" : "/images/white_bishop.png",
              pieceName: "Bishop",
              turn: turn
            });
}

var make = Bishop;

export {
  getOptionsByDirection ,
  getMoveOptions ,
  make ,
}
/* Game Not a pure module */
