// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var CellNotFound = /* @__PURE__ */Caml_exceptions.create("Utils.CellNotFound");

function getTeamFromPieceType(pieceType) {
  if (typeof pieceType === "number") {
    return ;
  } else {
    return pieceType._0;
  }
}

function isCellOccupied(cellId, cells) {
  var c = cells.find(function (c) {
        return c.id === cellId;
      });
  if (c !== undefined) {
    return c.pieceType !== /* Blank */0;
  }
  throw {
        RE_EXN_ID: CellNotFound,
        _1: "No cell found in isCellOccupied",
        Error: new Error()
      };
}

function checkOppositeTeam(id, cells, team) {
  var c = Belt_Array.get(cells, id);
  var currentCell;
  if (c !== undefined) {
    currentCell = c;
  } else {
    throw {
          RE_EXN_ID: CellNotFound,
          _1: "No currentCell in checkOppositeTeam at id " + String(id) + "",
          Error: new Error()
        };
  }
  var currentCellTeam = getTeamFromPieceType(currentCell.pieceType);
  if (currentCellTeam !== undefined) {
    return currentCellTeam !== team;
  } else {
    return false;
  }
}

function isOwnTeamCell(id, team, cells) {
  var cell = Belt_Array.get(cells, id);
  if (cell !== undefined) {
    return Belt_Option.getWithDefault(Belt_Option.map(getTeamFromPieceType(cell.pieceType), (function (t) {
                      return t === team;
                    })), false);
  } else {
    return false;
  }
}

function updateThreatState(prevThreat, team, isThreat) {
  if (team) {
    return [
            prevThreat[0],
            isThreat
          ];
  } else {
    return [
            isThreat,
            prevThreat[1]
          ];
  }
}

export {
  CellNotFound ,
  getTeamFromPieceType ,
  isCellOccupied ,
  checkOppositeTeam ,
  isOwnTeamCell ,
  updateThreatState ,
}
/* No side effect */
